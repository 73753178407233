/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "rugged-and-powerful-hearing-aid-performance",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rugged-and-powerful-hearing-aid-performance",
    "aria-label": "rugged and powerful hearing aid performance permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rugged and Powerful Hearing Aid Performance"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Smart engineers at Unitron designed the Max ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aid"), " for the demanding lifestyles of people with severe to profound hearing loss. Two models, the Max SP and the Max UP, offer different levels of power to match your hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Both Max models work to make speech as audible as possible, while keeping sound comfortable. This presents an engineering challenge. When you have severe hearing loss, there’s little headroom between the level at which you hear sounds and the level at which sounds become painful."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Fast-acting Sound Stabilization technology reduces the wind noise that you hear outdoors. The technology also suppresses annoying impulse sounds such as dishes clanging. This makes the sound you hear more comfortable."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Both models come with the IP68 rating, which means that the hearing aids resist dust and water. In a laboratory environment the hearing aids still worked after being in over a meter of plain water for 30 minutes. We don’t recommend that you go swimming with these aids, but it’s nice to know that you’ll still be going strong after the afternoon downpour."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your hearing professional has access to three pre-set programs that reflect the diversity among people with severe to profound hearing loss. If you’ve worn hearing aids since the days of analog aids, you may prefer the Classic setting with less signal processing and directionality. On the other hand, you might want to take advantage of the latest digital signal technologies in the Conventional or Current modes. If you’re not sure, you can try all of these modes. It’s simply a matter of hooking the hearing aids up to the computer and reprogramming."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If your ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), " shows that you have little useable hearing in the high pitches, your hearing care professional may turn on the frequency compression feature. This feature will shift higher-pitched sounds towards lower pitches. While it takes a few days to adjust to this different way of listening to sound, many people find that this feature allows them to access more sound than before."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unitron Max hearing aids are designed to keep you aware of sounds around you, even if the directional mode is activated. In some hearing aids, directional processing emphasizes sounds from one direction and causes you to miss sounds coming from other directions. Unitron’s engineers designed the aids to provide enough directionality to help you hear speech better in noise, while at the same time making sure you don’t miss a car coming at you in the parking lot."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You’ll also appreciate the strong feedback manager. With powerful hearing aids, you need the best feedback cancellation system possible so that people around you don’t hear squealing from your hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "super-power-technology-customized-to-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#super-power-technology-customized-to-you",
    "aria-label": "super power technology customized to you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Super Power Technology Customized to You"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With six color choices, the Unitron Max can discreetly blend into your hair color, or stand out as a fashion accessory. It’s up to you to choose your style. Perhaps you would like each hearing aid to be a different color. Don’t forget that you can also customize your earmold colors."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Although the hearing aids adjust themselves automatically to many listening environments with SoundCore technology, you can still tweak the sound to your liking with a volume control and a program control. You’ll work with your hearing care professional to create a set of programs for you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hearing aids include a telecoil setting. Simply switch to the telecoil and you’ll hear much more clearly over the phone and in venues with loop systems."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Max SP model takes a size 13 battery, while the Max UP model takes a long-lasting size 675 battery. You may opt for an environmentally-friendly rechargeable battery."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-hearcom-personalized-service",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-hearcom-personalized-service",
    "aria-label": "the hearcom personalized service permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The hear.com Personalized Service"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you would like to find out if the Unitron Max hearing aid is right for you, contact us today by filling out the form on our website. One of our experts will get in touch with you and tell you all about our 45 day risk-free trial. Once your initial questions are answered and you decide to move forward with, the expert will set an appointment for you with a local hearing care professional to evaluate your hearing and make a recommendation. The hearing care professional will program the hearing aids to your hearing loss and teach you how to take care of your devices."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Every step of the way, the hear.com expert will follow your progress. We want you to hear the best that you can, and that includes giving you information on financial assistance if you need it."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You’ll have 45 days to try the hearing aids. If you’re not satisfied, simple return them and receive a full refund."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Unitron Max now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
